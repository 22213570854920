import { Program, Site } from 'geooptix.ng-common/models';


export class ProgramOverview extends Program {

  ProjectsUserCanRead: string[];
  Sites: Site[];
  ProjectCount: number;
  SiteCount: number;
  WorkOrderCount: number;
  SampleCount: number;
  UserCount: number;
  SubscriptionType: SubscriptionType;

  constructor(item?: any) {
    super(item);
  }

}

export class SubscriptionType {
  SubscriptionTypeID: number;
  CanonicalName: string;
  Name: string;
  Description: string;
  Metadata: any;
}
