import { WorkOrder } from 'geooptix.ng-common/models';


export class WorkOrderOverview extends WorkOrder {

  ProjectCanonicalName: string;
  ProjectName: string;

  ProtocolCount: number;
  SiteCount: number;
  SampleCount: number;
  SampleStatusSummary: { [key: string]: number };

  AllowedStatusTransitions: string[];
  

  constructor(item?: any) {
    super(item);
  }

  static clone(item: WorkOrderOverview): WorkOrderOverview {
    return new WorkOrderOverview(
      JSON.parse(JSON.stringify(item))
    );
  }

}
