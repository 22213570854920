import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { MessageService } from 'primeng/api';
import { Observable, throwError as _throw } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiRouteBuilderService } from '../api-route-builder/api-route-builder.service';
import { BusyService } from '../busy/busy.service';


@Injectable({
    providedIn: 'root'
  })
export class ApiService {

    private callsInFlight: number;

    constructor(
        private apiRouteBuilder: ApiRouteBuilderService,
        private busyService: BusyService,
        private http: HttpClient,
        private messageService: MessageService,
        private oauthService: OAuthService,
        private router: Router,
        ) {
        this.callsInFlight = 0;
    }

    getFromData(endpoint: string, queryParams?: any, setBusyGlobally = true): Observable<any> {
        const apiRoute = this.apiRouteBuilder.getDataApiRoute();

        if (setBusyGlobally) {
            this.busyService.setBusy(true);
            this.callsInFlight++;
        }
        return this.http.get(apiRoute + '/' + endpoint, { params: queryParams })
            .pipe(
                map((response: any) => {
                    return this.handleResponse(response, setBusyGlobally);
                }),
                catchError((error: any) => {
                    return this.handleError(error);
                })
            );
    }

    getFromSearch(endpoint: string, queryParams?: any, setBusyGlobally = true): Observable<any> {
        const apiRoute = this.apiRouteBuilder.getSearchApiRoute();

        if (setBusyGlobally) {
            this.busyService.setBusy(true);
            this.callsInFlight++;
        }
        return this.http.get(apiRoute + '/' + endpoint, { params: queryParams })
            .pipe(
                map((response: any) => {
                    return this.handleResponse(response, setBusyGlobally);
                }),
                catchError((error: any) => {
                    return this.handleError(error);
                })
            );
    }

    getFromSystem(endpoint: string, queryParams?: any, setBusyGlobally = true): Observable<any> {
        const apiRoute = this.apiRouteBuilder.getSystemApiRoute();

        if (setBusyGlobally) {
            this.busyService.setBusy(true);
            this.callsInFlight++;
        }
        return this.http.get(apiRoute + '/' + endpoint, { params: queryParams })
            .pipe(
                map((response: any) => {
                    return this.handleResponse(response, setBusyGlobally);
                }),
                catchError((error: any) => {
                    return this.handleError(error);
                })
            );
    }

    postToSystem(endpoint: string, data: any, setBusyGlobally = true): Observable<any> {
        if (setBusyGlobally) {
            this.busyService.setBusy(true);
            this.callsInFlight++;
        }

        const apiRoute = this.apiRouteBuilder.getSystemApiRoute();
        data = JSON.parse(JSON.stringify(data, this.requestCleaner));

        return this.http.post(apiRoute + '/' + endpoint, data)
            .pipe(
                map((response: any) => {
                    return this.handleResponse(response, setBusyGlobally);
                }),
                catchError((error: any) => {
                    return this.handleError(error);
                })
            );
    }

    getFromProgram(endpoint: string, supressErrorMessage = false, setBusyGlobally = true, httpOptions?: any): Observable<any> {
        const apiRoute = this.apiRouteBuilder.getProgramApiRoute();

        if (setBusyGlobally) {
            this.busyService.setBusy(true);
            this.callsInFlight++;
        }
        return this.http.get(apiRoute + '/' + endpoint, httpOptions)
            .pipe(
                map((response: any) => {
                    return this.handleResponse(response, setBusyGlobally);
                }),
                catchError((error: any) => {
                    return this.handleError(error, supressErrorMessage, setBusyGlobally);
                })
            );
    }

    postToProgram(endpoint: string, data: any, setBusyGlobally = true): Observable<any> {
        if (setBusyGlobally) {
            this.busyService.setBusy(true);
            this.callsInFlight++;
        }

        const apiRoute = this.apiRouteBuilder.getProgramApiRoute();
        data = JSON.parse(JSON.stringify(data, this.requestCleaner));

        return this.http.post(apiRoute + '/' + endpoint, data)
            .pipe(
                map((response: any) => {
                    return this.handleResponse(response, setBusyGlobally);
                }),
                catchError((error: any) => {
                    return this.handleError(error);
                })
            );
    }

    patchToProgram(endpoint: string, data: any, setBusyGlobally = true): Observable<any> {
        if (setBusyGlobally) {
            this.busyService.setBusy(true);
            this.callsInFlight++;
        }

        const apiRoute = this.apiRouteBuilder.getProgramApiRoute();
        data = JSON.parse(JSON.stringify(data, this.requestCleaner));

        return this.http.patch(apiRoute + '/' + endpoint, data)
            .pipe(
                map((response: any) => {
                    return this.handleResponse(response, setBusyGlobally);
                }),
                catchError((error: any) => {
                    return this.handleError(error);
                })
            );
    }

    putToProgram(endpoint: string, data: any, setBusyGlobally = true): Observable<any> {
        if (setBusyGlobally) {
            this.busyService.setBusy(true);
            this.callsInFlight++;
        }

        const apiRoute = this.apiRouteBuilder.getProgramApiRoute();
        data = JSON.parse(JSON.stringify(data, this.requestCleaner));

        return this.http.put(apiRoute + '/' + endpoint, data)
            .pipe(
                map((response: any) => {
                    return this.handleResponse(response, setBusyGlobally);
                }),
                catchError((error: any) => {
                    return this.handleError(error);
                })
            );
    }

    deleteToProgram(endpoint: string, setBusyGlobally = true): Observable<any> {
        if (setBusyGlobally) {
            this.busyService.setBusy(true);
            this.callsInFlight++;
        }

        const apiRoute = this.apiRouteBuilder.getProgramApiRoute();

        return this.http.delete(apiRoute + '/' + endpoint)
            .pipe(
                map((response: any) => {
                    return this.handleResponse(response, setBusyGlobally);
                }),
                catchError((error: any) => {
                    return this.handleError(error);
                })
            );
    }


    getCurrentProgramCName() {
        return this.apiRouteBuilder.getCurrentProgramCName();
    }

    private handleResponse(response: any, clearBusyGlobally = true): any {
        if (clearBusyGlobally) {
            this.callsInFlight--;
            if (this.callsInFlight <= 0) {
                this.callsInFlight = 0;
                this.busyService.setBusy(false);
            }
        }
        return response;
    }

    private handleError(error: any, supressErrorMessage = false, clearBusyGlobally = true): Observable<any> {
        if (clearBusyGlobally) {
            this.busyService.setBusy(false);
            this.callsInFlight = 0;
        }

        if (!supressErrorMessage) {
            if (error && error.status === 401) {
                this.messageService.clear();
                this.messageService.add({
                    severity: 'error',
                    summary: 'Authentication',
                    detail: 'Access token expired...'
                });
                this.oauthService.initImplicitFlow();
            } else if (error && error.status === 403) {
                this.messageService.clear();
                this.messageService.add({
                    severity: 'error',
                    summary: 'Not Authorized',
                    detail: `You've been redirected to the ${this.router.url.indexOf('inner:project') >= 0 ? 'home' : 'project'} page.`
                });

                if (this.router.url.indexOf('inner:project') >= 0) {
                    this.router.navigate(
                        [ '/not-authorized' ]
                    );
                } else {
                    this.router.navigate(
                        [ '/program/main', { outlets: { inner: ['project'] } } ]
                    );
                }

            } else if (error.error && typeof error.error === 'string') {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.error
                });
            } else if (error.error && !(error.error instanceof ProgressEvent)) {
                for (const key of Object.keys(error.error)) {
                    this.messageService.addAll(
                        (error.error[key] as string[]).map((fe: string) => {
                            return {
                                severity: 'error',
                                summary: key,
                                detail: fe
                            };
                        })
                    );
                }
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Server Error',
                    detail: `Oops! Something went wrong and we couldn't complete the action...`
                });
            }
        }

        return _throw(error);
    }

    private requestCleaner(name: string, val: any) {
        if ((name || '').toString().indexOf('$') === 0) {
            return undefined; // remove from data
        } else {
            return val; // return as is
        }
    }
}
