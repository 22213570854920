import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Program } from 'geooptix.ng-common/models';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiRouteBuilderService } from '../../services/api-route-builder/api-route-builder.service';
import { DomainParserService } from '../../services/domain-parser/domain-parser.service';
import { SystemService } from '../../services/system/system.service';


@Injectable({
    providedIn: 'root'
})
export class ProgramAccessGuard implements CanActivate {

    constructor(
        private oauthService: OAuthService,
        private httpClient: HttpClient,
        private router: Router,
        private api: ApiRouteBuilderService,
        private userService: SystemService,
        private domainParser: DomainParserService,
    ) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const claims = this.oauthService.getIdentityClaims();
        const currentUserGlobalID = claims && claims.hasOwnProperty('sub') ? claims['sub'] : null;
        const programCName = this.domainParser.getProgramCName();

        return !currentUserGlobalID ? of(false) : this.userService.getUser(currentUserGlobalID)
        .pipe(
            map((request) => {
                const programCNames = (request['Programs'] || [])
                .map((program: Program) => {
                    return program.CanonicalName;
                });

                const isMember = programCNames.indexOf(programCName) >= 0;
                if (!isMember) {
                    const doesProgramExistURL = this.api.getSystemApiRoute() + '/system/info/programs/' + programCName;
                    this.httpClient.get(doesProgramExistURL)
                    .subscribe((next) => {
                            this.router.navigate(['/not-authorized'], { queryParams: { return: state.url } });
                        },
                        error => {
                            if (error.status === 404) {
                                this.router.navigate(['/not-found'], { queryParams: { return: state.url } });
                            }
                        });
                } else {
                    return isMember;
                }
            }),
            catchError((error) => {
                if (error.status === 403) {
                    this.router.navigate(['/not-authorized'], { queryParams: { return: state.url } });
                } else if (error.status === 404) {
                    this.router.navigate(['/not-found'], { queryParams: { return: state.url } });
                } else {
                    return of(false);
                }
            })
        );
    }
}
