<p-dialog
  styleClass="confirm-dialog"
  [closeOnEscape]="true"
  [modal]="true"
  [resizable]="false"
  [contentStyle]="{'overflow':'auto'}"
  [(visible)]="isVisible"
  [focusOnShow]="false"
  (keyup.enter)="submit(true)"
  (keyup.escape)="dismiss()">
  <p-header>
    {{ title }}
  </p-header>

    <p style="margin-top: 12px;"
      [innerHtml]="message">
    </p>

    <br>
    <p *ngIf="confirmValue"
      class="ui-float-label">
      <input #autoFocusInput
        pInputText
        type="text"
        id="value"
        [(ngModel)]="value"
        autocomplete="false"
        required>
      <!--<label for="value">Confirm Value</label>-->
    </p>

  <p-footer>
    <div class="flex-1"></div>

    <p-button *ngIf="!hideNoButton"
      styleClass="white"
      icon="fa fa-times"
      tabindex="-1"
      label="{{ noText }}"
      (click)="submit(false)">
    </p-button>

    <p-button *ngIf="maybeText"
      styleClass="white"
      icon="fa fa-check"
      tabindex="-1"
      label="{{ maybeText }}"
      (click)="submit(undefined)">
    </p-button>
    
    <p-button
      styleClass="primary"
      icon="fa fa-check"
      [label]="yesText"
      [disabled]="confirmValue !== undefined && confirmValue !== null && confirmValue != value"
      (click)="submit(true)">
    </p-button>
  </p-footer>
</p-dialog>
