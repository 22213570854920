import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api/api.service";
import { ProgramUserSettingTypeEnum } from "./program-user-setting-type-enum" 

@Injectable({
    providedIn: "root"
})
export class UserSettingService {
    constructor(
        private apiService: ApiService,
    ) {

    }

    ngOnInit(): void {

    }


    postUserSetting(type: ProgramUserSettingTypeEnum, setting: any): Observable<object> {
        return this.apiService.postToProgram(`program-user-settings/user-setting-types/${type}`, setting);
    }

    getAllUserSettings(): Observable<object> {
        return this.apiService.getFromProgram("program-user-settings");
    }

    getUserSettingsForType(type: ProgramUserSettingTypeEnum): Observable<object[]> {
        return this.apiService.getFromProgram(`program-user-settings/user-setting-types/${type}`);
    }

    getUserSetting(id: number): Observable<object> {
        return this.apiService.getFromProgram(`program-user-settings/${id}`);
    }

    putUserSetting(id: number, setting: any): Observable<object> {
        return this.apiService.putToProgram(`program-user-settings/${id}`, setting);
    }

    deleteUserSetting(id: number): Observable<object> {
        return this.apiService.deleteToProgram(`program-user-settings/${id}`);
    }
}

