
export enum StationType {
  Campbell_Scientific_Datalogger = 2,
  Paige_Wireless = 1,

  Other = 9999,
}

export class Station {

  StationID: number;
  ProjectCanonicalName: string;
  SiteCanonicalName: string;
  CanonicalName: string;
  Name: string;
  Description: string;

  StationTypeID: StationType;
  Definition: any;

  constructor(item?: any) {
    Object.assign(this, item);
  }

  static getStationTypeDefinitionTemplateFields(stationTypeID: StationType, sensorType?: string) {
    switch (stationTypeID) {
      case StationType.Paige_Wireless:
        return {
          disabled: false,
          sensorType: sensorType || 'WellPressure',
          dataDestinations: [
            {
              destinationType: 'folder',
              formats: ['csv', 'json']
            },
            {
              destinationType: 'influxDB',
            }
          ]
        };
      case StationType.Campbell_Scientific_Datalogger:
        return {
          dataDestinations: [{
            destinationType: 'folder',
            formats: ['csv', 'json']
          }]
        };
    }
  }

}
