<div class="user-auth display-table">
  <a *ngIf="hasToken() && getCurrentGivenName()"
    href="mailto:geooptix-support@esassoc.com" target="_blank">
    SUPPORT
  </a>

  <a *ngIf="hasToken() && getCurrentGivenName()"
    style="text-transform: uppercase;"
    href="javascript:void(0);"
    (click)="showProgramSelector = !showProgramSelector">
    {{ showProgramSelector ? 'Hide' : 'Show' }} {{ getCurrentGivenName() }}'s Programs
  </a>
  <span *ngIf="windowWidth >= SIZE_MD" class="display-table">
    <a *ngIf="hasToken()" href="javascript:void(0);" (click)="logout()">SIGN OUT</a>
    <a *ngIf="!hasToken()" href="javascript:void(0);" (click)="login()">SIGN IN</a>
    <span class="button-wrapper">
      <button *ngIf="!hasToken()"class="button" (click)="requestDemo()">REQUEST A DEMO</button>
    </span>
  </span>

  <span *ngIf="windowWidth < SIZE_MD" class="display-table">
    <a href="javascript:void(0);"
      class="button-menu"
      (click)="menu.toggle($event)">
      <span class="fa fa-bars"></span>
    </a>
  </span>

  <p-menu #menu
    [popup]="'popup'"
    [model]="menuItems">
  </p-menu>
</div>

<go-program-selector *ngIf="showProgramSelector"></go-program-selector>
