import { ProgramUserPermissions, ProjectUserPermissions, Project, Site, WorkOrder } from 'geooptix.ng-common/models';


export class ProjectOverview extends Project {

  CurrentUserProgramPermissions: ProgramUserPermissions;
  CurrentUserProjectPermissions: ProjectUserPermissions;
  Sites: Site[];
  WorkOrders: WorkOrder[];
  ProtocolCount: number;

  constructor(item?: any) {
    super(item);
  }

}
