import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DomainParserService } from '../domain-parser/domain-parser.service';

@Injectable({
    providedIn: 'root'
  })
export class ApiRouteBuilderService {
    constructor(private domainParser: DomainParserService) { }

    getSystemApiRoute(): string {
        const systemApiRoute = `https://${environment.apiPrefix}.geooptix.com${environment.apiPortNumber}`;

        return systemApiRoute;
    }

    getProgramApiRoute(): string {
        const programCName = this.domainParser.getProgramCName();

        const programApiRoute = `https://${programCName}.${environment.apiPrefix}.geooptix.com${environment.apiPortNumber}`;

        return programApiRoute;
    }

    getDataApiRoute(): string {
        const programCName = this.domainParser.getProgramCName();

        const dataApiRoute = `https://${programCName}.${environment.dataPrefix}.geooptix.com${environment.dataPortNumber}`;

        return dataApiRoute;
    }


    getSearchApiRoute(): string {
        const programCName = this.domainParser.getProgramCName();

        const dataApiRoute = `https://${programCName}.${environment.searchPrefix}.geooptix.com${environment.searchPortNumber}`;

        return dataApiRoute;
    }

    public getCurrentProgramCName() {
        const programCName = this.domainParser.getProgramCName();
        return programCName;
    }


}
