import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from "./environments/environment";

if (environment.gTagTrackingId) {
  // add Google Analytics script to <head>
  const googletagmanagerScript = document.createElement('script');
  googletagmanagerScript.async = true;
  googletagmanagerScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gTagTrackingId;
  
  const gtagSetupScript = document.createElement('script');
  gtagSetupScript.innerHTML = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());`;

  document.head.appendChild(googletagmanagerScript);
  document.head.appendChild(gtagSetupScript);
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
.bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
.catch(err => console.log(err));
