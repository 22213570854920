import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'go-loading',
    templateUrl: './loading.page.html',
    styleUrls: ['./loading.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingPage implements OnInit {

    constructor() {}

    ngOnInit() {
    }

}
