import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProgramAccessGuard, UnauthorizedAccessGuard } from './shared/guards';
import { LoadingPage, NotAuthorizedPage, NotFoundPage } from './shared/pages';




const routes: Routes = [
    {
        path: '',
        component: LoadingPage
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'program',
        canActivate: [UnauthorizedAccessGuard, ProgramAccessGuard],
        loadChildren: () => import('./program/program.module').then(m => m.ProgramModule)
    },
    {
        path: 'not-authorized',
        component: NotAuthorizedPage
    },
    {
        path: '**',
        component: NotFoundPage
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
