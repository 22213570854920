import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';


@Injectable({
    providedIn: 'root'
})
export class SystemService {

    private timeZones: SelectItem[];

    constructor(
        private apiService: ApiService,
    ) {

    }

    getTimezones(): Observable<SelectItem[]> {
        return this.timeZones ?
            of(this.timeZones) :
            this.apiService.getFromSystem('system/timezones')
            .pipe(
                map((response: any[]) => {
                    this.timeZones = (response || []).map((x: any) => {
                        return { value: x.Key, label: `${x.Key} (${x.Value})` };
                    });
                    this.timeZones.splice(0, 0, { value: null, label: 'UTC' })
                    return this.timeZones;
                })
            );
    }

    getUsers(userType?: number): Observable<object> {
        return this.apiService.getFromSystem('system/users' + userType ? '?userType=' + userType : '');
    }

    getUser(userGlobalID: string): Observable<object> {
        return this.apiService.getFromSystem('system/users/' + userGlobalID);
    }

    upsertUserClaims(): Observable<object> {
        return this.apiService.postToSystem('system/users/claims', null);
    }
}
