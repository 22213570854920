import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class DomainParserService {

    private lastHostNameParsed: string;

    private environment: string;
    private programCName: string;

    constructor() { }

    private parseDomainParts() {
        this.lastHostNameParsed = window.location.hostname;
        const domainParts = this.lastHostNameParsed.split('.');

        if (domainParts.length === 4) {
            this.programCName = domainParts[0];
            this.environment = domainParts[1];
        } else if (domainParts.length === 3) {
            this.environment = domainParts[0];
            if (this.environment !== 'www' && this.environment !== 'qa' && this.environment !== 'dev')
            {
                this.environment = 'www';
                this.programCName = domainParts[0];
            } else {
                this.programCName = '';
            }
        } else if (domainParts.length === 2) {
            this.environment = 'www';
            this.programCName = '';
        }
    }

    public getHostName() {
        if (this.lastHostNameParsed !== window.location.hostname) {
            this.parseDomainParts();
        }
        return this.lastHostNameParsed;
    }

    public getEnvironment() {
        if (this.lastHostNameParsed !== window.location.hostname) {
            this.parseDomainParts();
        }
        return this.environment;
    }

    public getProgramCName() {
        if (this.lastHostNameParsed !== window.location.hostname) {
            this.parseDomainParts();
        }
        return this.programCName;
    }
}
