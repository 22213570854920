import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationError, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Observable, Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { BaseDialog, ConfirmDialog } from './shared/dialogs';
import { BusyService, CookieStorageService, DomainParserService } from './shared/services';
import { SystemService } from './shared/services/system/system.service';



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush, // this breaks primeng messageService I believe...
})
export class AppComponent implements OnInit {

    @ViewChild(ConfirmDialog, { static: true }) confirmDialog: BaseDialog;

    programCName: string;
    userClaimsUpsertStarted = false;
    ignoreSessionTerminated = false;

    constructor(
        private busyService: BusyService,
        private cdr: ChangeDetectorRef,
        private cookieStorageService: CookieStorageService,
        private domainParser: DomainParserService,
        private oauthService: OAuthService,
        private router: Router,
        private userService: SystemService,
    ) {
    }

    ngOnInit(): void {
        this.router.events
        .subscribe((event: any) => {
            if (event instanceof RouteConfigLoadStart) { // lazy loaded route started
                this.busyService.setBusy(true);
            } else if (event instanceof RouteConfigLoadEnd) { // lazy loaded route ended
                this.busyService.setBusy(false);
            } else if (event instanceof NavigationEnd) {
                window.scrollTo(0, 0);

                if (environment.gTagTrackingId) {
                    (window as any).gtag('config', environment.gTagTrackingId, { 'page_path': event.urlAfterRedirects });
                }
            } else if (event instanceof NavigationError) {
                if (event.error.message.indexOf('Loading chunk') >= 0) {
                  this.handleLoadingChunkError(event);
                }
            }
        });

        this.configureAuthService()
        .subscribe(() => {
            this.checkCurrentUrl(window.location.pathname);
        });
    }

    private handleLoadingChunkError(ne: NavigationError) {
        this.busyService.setBusy(false);
        this.confirmDialog.show({
          title: 'Network Error',
          message: 'Sorry we were unable to load the page you were trying to visit.',
          yesText: 'Retry',
          hideNoButton: true,
        });
        this.confirmDialog.onClose = (result: boolean) => {
            if (result) {
                this.router.navigateByUrl(ne.url)
            }
        };
        this.cdr.markForCheck();
      }

    private configureAuthService(): Observable<void> {
        const subject = new Subject<void>();

        this.oauthService.configure(environment.geoOptixAuthConfiguration);
        this.oauthService.setStorage(this.cookieStorageService);
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        this.oauthService.loadDiscoveryDocument();

        const claims = this.oauthService.getIdentityClaims();
        const currentUserGlobalID = claims && claims.hasOwnProperty('sub') ? claims['sub'] : null;
        this.setAppInsightsUserContext(currentUserGlobalID);

        this.oauthService.events
            .subscribe((e) => {
                // console.log(e)
                switch (e.type) {
                    case 'discovery_document_loaded':
                        if ((e as OAuthSuccessEvent).info) {
                            subject.next();
                            subject.complete();
                        }
                        break;
                    case 'token_received':
                        if (!this.userClaimsUpsertStarted) {
                            this.userClaimsUpsertStarted = true;
                            setTimeout(() => {
                                this.userService.upsertUserClaims()
                                .subscribe((success: any) => {
                                    this.setAppInsightsUserContext(success.GlobalId);
                                    this.userClaimsUpsertStarted = false;
                                    subject.next();
                                    subject.complete();
                                }, (error) => {
                                    console.error('Error getting user claims:', error);
                                });
                            });
                        }

                        break;
                    case 'session_changed':
                        // when the user logins from no-program URL and then jumps to the program URL,
                        // the oAuthService triggers a session-changed followed by a session_terminated...
                        // however the token still works as expected.
                        // ATTENTION: Need to verify that on session expiration (and hence session_terminated) this session_changed doesn't get called...
                        this.ignoreSessionTerminated = true;
                        break;
                    case 'session_terminated':
                        if (!this.ignoreSessionTerminated) {
                            console.warn('Your session has been terminated!');
                            this.cookieStorageService.removeAll();

                            this.setAppInsightsUserContext(null);
                        }
                        this.ignoreSessionTerminated = false;
                        break;
                }

            });

        return subject.asObservable();
    }

    private checkCurrentUrl(url: string) {
        this.programCName = this.domainParser.getProgramCName();
        this.oauthService.tryLogin();
        if (this.oauthService.hasValidAccessToken()) {
            // if default route, force one of program or home routes
            if (url === '/') {
                setTimeout(() => {
                    if (this.programCName) {
                        this.router.navigate(['/program']);
                    } else {
                        this.router.navigate(['/home']);
                    }
                }, 500);
            }
        } else {
            if (this.programCName) {
                if (environment.prefix !== 'www') {
                    this.cookieStorageService.removeAllProd();
                }
                this.oauthService.initImplicitFlow();
            } else if (url === '/' && !window.location.hash) {
                this.router.navigate(['/home']);
            }
        }
    }

    private setAppInsightsUserContext(currentUserGlobalID: string) {
        if ((window as any).appInsights) {
            if (currentUserGlobalID) {
                (window as any).appInsights.setAuthenticatedUserContext(currentUserGlobalID, null, true);
            } else {
                (window as any).appInsights.clearAuthenticatedUserContext();
            }
        }
    }
}
