import { Injectable } from '@angular/core';
import { Project, ProjectUserPermissions, User } from 'geooptix.ng-common/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../../shared/services';
import { Wizard, ProjectOverview } from '../../models';


@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  currentUserProjectPermissions: ProjectUserPermissions;

  constructor(private apiService: ApiService) {

  }

  list(allProjects = false, setBusyGlobally = true): Observable<Project[]> {
    return this.apiService.getFromProgram(
      'projects' + (allProjects ? '?allProjects=true' : ''),
      false,
      setBusyGlobally
    ).pipe(
      map((items: Project[]) => {
        const result = [];
        for (const item of items) {
          result.push(new Project(item));
        }
        return result;
      })
    );
  }

  getOverview(projectCName: string, setBusyGlobally = true): Observable<ProjectOverview> {
    return this.apiService.getFromProgram(`project-overview-web/${projectCName}`, false, setBusyGlobally)
      .pipe(
        map((item: ProjectOverview) => {
          this.currentUserProjectPermissions = item.CurrentUserProjectPermissions;
          return new ProjectOverview(item);
        })
      );
  }

  put(project: Project): Observable<Project> {
    return this.apiService.putToProgram(
      `projects/${project.CanonicalName}`,
      project
    ).pipe(
        map((item: Project) => {
          return new Project(item);
        })
      );
  }

  postProject(project: Project): Observable<Project> {
    return this.apiService.postToProgram('projects', project)
      .pipe(
        map((item: Project) => {
          return new Project(item);
        })
      );
  }

  postOverview(wizard: Wizard): Observable<Project> {
    return this.apiService.postToProgram(
      'project-overview',
      this.convertToProjectOverviewCreateDTO(wizard)
    ).pipe(
        map((item: Project) => {
          return new Project(item);
        })
      );
  }

  delete(project: Project): Observable<any> {
    const endpoint = `projects/${project.CanonicalName}`;
    return this.apiService.deleteToProgram(endpoint);
  }

  private convertToProjectOverviewCreateDTO(wizard: Wizard) {
    const dto: any = JSON.parse(JSON.stringify(wizard.project));

    for (const site of dto.Sites) {
      for (const sample of site.Samples || []) {
        sample.WorkOrderClientID = sample.WorkOrderCanonicalName;
        delete sample.WorkOrderCanonicalName;
      }
    }

    for (const wo of dto.WorkOrders) {
      wo.TeamMembers = wo.TeamMembers.map((tm: User) => {
        return tm.GlobalID;
      });
      wo.ClientID = wo.CanonicalName;
      delete wo.CanonicalName;
    }

    // console.log(JSON.stringify(dto, null, 2));
    return dto;
  }

  listProjectUsers(projectCName: string, setBusyGlobally = true): Observable<User[]> {
    return this.apiService.getFromProgram(`projects/${projectCName}/users`, false, setBusyGlobally);
  }

  listProjectUsersPermissions(projectCName: string, setBusyGlobally = true): Observable<ProjectUserPermissions[]> {
    return this.apiService.getFromProgram(`projects/${projectCName}/users/permissions`, false, setBusyGlobally);
  }

  postProjectUser(projectCName: string, userGlobalID: string, roleID: number): Observable<User> {
    const postObj = { GlobalID: userGlobalID, ProjectRoleID: roleID };
    return this.apiService.postToProgram(`projects/${projectCName}/users`, postObj);
  }

  clearProjectUserLocalPermissions(): void {
    this.currentUserProjectPermissions = undefined;
  }

  getProjectUserPermissions(
    projectCName: string, userGlobalID: string, supressErrorMessage = false, setBusyGlobally = true
  ): Observable<ProjectUserPermissions> {
    return this.currentUserProjectPermissions ?
      of(this.currentUserProjectPermissions) :
      this.apiService.getFromProgram(`projects/${projectCName}/users/${userGlobalID}/permissions`, supressErrorMessage, setBusyGlobally)
        .pipe(
          map((pup: ProjectUserPermissions) => {
            this.currentUserProjectPermissions = pup;
            return pup;
          })
        );
  }

  putProjectUserPermissions(
    projectCName: string, userGlobalID: string, projectRoleID: number
  ): Observable<ProjectUserPermissions> {
    return this.apiService.putToProgram(`projects/${projectCName}/users/${userGlobalID}/permissions`, { ProjectRoleID: projectRoleID })
      .pipe(
        map((pup: ProjectUserPermissions) => {
          if (this.currentUserProjectPermissions && this.currentUserProjectPermissions.GlobalID === userGlobalID) {
            this.currentUserProjectPermissions = pup;
          }
          return pup;
        })
      );
  }

  deleteProjectUser(projectCName: string, userGlobalID: string): Observable<ProjectUserPermissions> {
    return this.apiService.deleteToProgram(`projects/${projectCName}/users/${userGlobalID}`);
  }
}
