import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BaseDialog } from '../base/base.dialog';


@Component({
  selector: 'go-confirm-dialog',
  templateUrl: './confirm.dialog.html',
  styleUrls: ['./confirm.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialog extends BaseDialog {

  title: string;
  message: string;
  yesText: string;
  noText: string;
  maybeText: string;
  confirmValue: string;
  hideNoButton: boolean;

  value: string;

  constructor(
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  init(data: any) {
    this.title = data.title;
    this.message = data.message;
    this.yesText = data.yesText || 'YES';
    this.noText = data.noText || 'NO';
    this.maybeText = data.maybeText;
    this.confirmValue = data.confirmValue;
    this.hideNoButton = data.hideNoButton;

    this.value = undefined;
  }

  submit(result: boolean) {
    if (
      result === false ||
      !(this.confirmValue !== undefined && this.confirmValue !== null && this.confirmValue != this.value)
    ) {
      this.close(result);
    }
  }
}
