export enum ImportState {
  Waiting = 1,
  InProgress = 2,
  Completed = 3,
  Error = 4,
}

export enum ImportType {
  ExcelSamples = 1,
}

export class Import {

  ImportID: number;
  ImportStateID: ImportState;
  ImportType: ImportType;
  ProgramID: number;
  ProjectID?: number;
  SiteID?: number;
  SampleID?: number;
  Metadata?: any;
  FileUrl: string;
  FileName: string;
  CreateDate: string;
  CreateUserID: number;
  UpdateDate: string;
  UpdateUserID: number;
  RequestDate: string;
  RequestUserID: number;

  ErrorMessage: string;

  constructor(item?: any) {
    Object.assign(this, item);
  }

}
