import { ChangeDetectorRef, ElementRef, ViewChild, Component } from '@angular/core';


export abstract class BaseDialog {

  @ViewChild('autoFocusInput') autoFocusInput: ElementRef;

  protected data: any;
  isVisible: boolean;
  onClose: (data: any) => void;

  constructor(
    protected cdr: ChangeDetectorRef,
  ) {

  }

  show(data?: any) {
    this.isVisible = true;
    this.data = data;
    this.init(this.data);

    this.cdr.markForCheck();

    setTimeout(() => {
      if (this.autoFocusInput) {
        this.autoFocusInput.nativeElement.focus();
      }
    });
  }

  protected abstract init(data: any);

  dismiss() {
    this.close(undefined);
  }

  protected close(data: any) {
    if (this.onClose) {
      this.onClose(data);
    }
    this.isVisible = false;

    this.cdr.markForCheck();
  }

  protected abstract submit(item: any);

}
