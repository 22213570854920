import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Program } from 'geooptix.ng-common/models';
import { environment } from '../../../../../../environments/environment';
import { CookieStorageService } from '../../../../services';
import { DomainParserService } from '../../../../services/domain-parser/domain-parser.service';
import { SystemService } from '../../../../services/system/system.service';



@Component({
    selector: 'go-program-selector',
    templateUrl: './program-selector.component.html',
    styleUrls: ['./program-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgramSelectorComponent implements OnInit {

    loading: boolean;
    env: string;
    portNumber: string;
    programs: Program[];
    urlPrefix: string;

    constructor(
        private cdr: ChangeDetectorRef,
        private cookieStorageService: CookieStorageService,
        private domainParser: DomainParserService,
        private oauthService: OAuthService,
        private userService: SystemService
    ) {
        this.env = this.domainParser.getEnvironment();
        if (this.env === 'dev') {
            this.portNumber = ':8081';
            this.env = this.env + '.'
        } else if (this.env === 'qa'){
            this.portNumber = '';
            this.env = this.env + '.';
        } else {
            this.env = '';
            this.portNumber = '';
        }

    }

    ngOnInit() {
        this.loading = true;
        if (this.oauthService.hasValidAccessToken()) {
            var claims = this.oauthService.getIdentityClaims();
            this.userService.getUser(claims['sub'])
            .subscribe(
                (success: any) => {
                    this.programs = (success['Programs'] || [])
                    .sort((a, b) => {
                        return (a.Name || '').localeCompare(b.Name || '');
                    });
                    this.loading = false;

                    this.cdr.markForCheck();
                },
                (error: any) => {
                    console.log(error);
                    this.loading = false;
                    // CG - if we ge Unauthorized on user claims, our cookies are probably mixed between environment, let's clear out prod if they exist.
                    if (environment.prefix !== 'www') {
                        this.cookieStorageService.removeAllProd();
                    }
                    this.cdr.markForCheck();
                }
            );
        } else {
            if (environment.prefix !== 'www') {
                this.cookieStorageService.removeAllProd();
            }
            this.oauthService.initImplicitFlow();
        }
    }
}

