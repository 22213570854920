import { Protocol, Site, Sample, WorkOrder } from 'geooptix.ng-common/models';


export class SampleOverview extends Sample {

  ProjectCanonicalName: string;
  ProjectName: string;

  Protocol: Protocol;
  Site: Site;
  WorkOrder: WorkOrder;

  AllowedStatusTransitions: string[];

  constructor(item?: any) {
    super(item);

    this.Protocol = new Protocol(item.Protocol);
    this.Site = new Site(item.Site);
    this.WorkOrder = new WorkOrder(item.WorkOrder);
  }

  static clone(item: SampleOverview): SampleOverview {
    return new SampleOverview(
      JSON.parse(JSON.stringify(item))
    );
  }

}
