import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { MenuItem } from 'primeng/api/menuitem';
import { ProjectsService } from 'src/app/program/services';
import { environment } from '../../../../../../environments/environment';
import { CookieStorageService, ProgramService } from '../../../../services';


@Component({
  selector: 'go-user-authentication',
  templateUrl: './user-authentication.component.html',
  styleUrls: ['./user-authentication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAuthenticationComponent implements OnInit {

  readonly SIZE_MD = 767;

  showProgramSelector: boolean;

  windowWidth: number;
  menuItems: MenuItem[];

  constructor(
    private cdr: ChangeDetectorRef,
    private cookieStorageService: CookieStorageService,
    private oauthService: OAuthService,
    private programService: ProgramService,
    private projectsService: ProjectsService,
  ) {
    this.showProgramSelector = false;
  }

  ngOnInit() {
    this.resize();

    this.buildMenu();
  }

  @HostListener('window:resize', ['$event'])
  resize(ev?: Event) {
      this.windowWidth = window.innerWidth;
  }

  buildMenu() {
    this.menuItems = [];

    if (this.hasToken()) {
      this.menuItems.push(
        { label: 'SIGN OUT', command: (event) => { this.logout(); }}
      );
    } else if (!this.hasToken()) {
      this.menuItems.push(
        { label: 'SIGN IN', command: (event) => { this.login(); }}
      );
      this.menuItems.push({ separator: true });
      this.menuItems.push(
        { label: 'REQUEST A DEMO', command: (event) => { this.requestDemo(); }}
      );
    }

    this.cdr.markForCheck();
  }

  login() {
    this.programService.clearProgramUserLocalPermissions();
    this.projectsService.clearProjectUserLocalPermissions();

    if (environment.prefix !== 'www') {
      this.cookieStorageService.removeAllProd();
    }
    this.oauthService.initImplicitFlow();
  }

  logout() {
    this.programService.clearProgramUserLocalPermissions();
    this.projectsService.clearProjectUserLocalPermissions();

    this.oauthService.logOut();

    setTimeout(() => {
      this.cookieStorageService.removeAll();
      this.cdr.markForCheck();
    });
  }

  hasToken() {
    return this.oauthService.hasValidAccessToken();
  }

  getCurrentGivenName() {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims || !claims.hasOwnProperty('given_name')) {
      return null;
    }
    return claims['given_name'];
  }

  requestDemo() {
    window.open(environment.requestDemoURL);
  }

}
