import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
// import { ApplicationInsights, IDependencyTelemetry, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './shared/interceptors/auth-interceptor/auth.interceptor';
import { SharedModule } from './shared/shared.module';
const { name, version } = require('../../package.json');



if (environment.appInsightsKey) {
  initAppInsights();
}

function initAppInsights() {
  /*(window as any).appInsights = new ApplicationInsights({ config: {
    appId: name + '@' + version,
    enableAutoRouteTracking: true,
    instrumentationKey: environment.appInsightsKey,
    maxAjaxCallsPerView: -1,
  } });

  (window as any).appInsights.loadAppInsights();

  (window as any).appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
    if (
      item &&
      item.baseData &&
      [0, 401].indexOf((item.baseData as IDependencyTelemetry).responseCode) >= 0
    ) {
      return false;
    }
  });*/
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor() {
  }

  handleError(error: any) {
    if (
      error &&
      error.status !== 401 && // Unauthorized
      error.status !== 403 && // Forbidden
      error.status !== 404 && // Not Found (can easily happen when looking for a unexisting .po file)
      (error.message || '').indexOf('ViewDestroyedError: Attempt to use a destroyed view: detectChanges') < 0 && // issue in the ngx-loading package...waiting for it to be updated.
      (error.message || '').indexOf('ExpressionChangedAfterItHasBeenCheckedError') < 0 && // this only happens in dev angular build - I'm sure
      (error.message || '').indexOf('Loading chunk') < 0 // also ignore loading chunk errors as they're handled in app.component NavigationError event
    ) {
      console.error(error);

      if ((window as any).appInsights) {
        (window as any).appInsights.trackException({
          exception: error.originalError || error
        });
      }
    } else if (error) {
      console.warn(error);
    }
  }

}



@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        // BrowserAnimationsModule,
        NoopAnimationsModule,
        HttpClientModule,
        OAuthModule.forRoot(),

        AppRoutingModule,

        SharedModule.forRoot(),
    ],
    exports: [
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
