<div class="program-selector">
  <div *ngIf="loading" class="item loading">
      Loading your programs...
  </div>

  <div *ngIf="!loading">
    <ul>
      <li *ngFor="let program of programs || []"
        class="item">
        <a href="https://{{ program.CanonicalName }}.{{ env }}geooptix.com{{ portNumber }}">
          {{ program.Name }}
        </a>
      </li>
    </ul>
    <div *ngIf="!programs?.length"
      class="item">
      <p style="font-size: 24px;">
        You are currently not a member of any programs.
      </p>
      <p>
        Please contact your program manager to gain access to your program's GeoOptix implementation.
      </p>
    </div>
  </div>
</div>
