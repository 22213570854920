export enum ExportState {
  Waiting = 1,
  InProgress = 2,
  Completed = 3,
  Error = 4,
}

export enum ExportType {
  Excel = 1,
}

export class Export {

  ExportID: number;
  ProgramID: number;
  ExportStateID: ExportState;
  ProjectID?: number;
  SiteID?: number;
  SampleID?: number;
  ProtocolCanonicalName: string;
  ProtocolVersionNumber: number;
  Scope?: string[] | any;
  ExportType: ExportType;
  IncludeFiles: boolean;
  FileUrl: string;
  FileName: string;
  CreateDate: string;
  CreateUserID: number;
  UpdateDate: string;
  UpdateUserID: number;
  RequestDate: string;
  RequestUserID: number;

  ErrorMessage: string;

  constructor(item?: any) {
    Object.assign(this, item);
  }

}
