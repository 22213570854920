import { Injectable } from '@angular/core';
import { Program, ProgramUserPermissions, SystemUserPermissions, User } from 'geooptix.ng-common/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProgramOverview } from 'src/app/program/models';
import { BarcodeRecord } from 'src/app/program/pages/lab/models/barcode-record.model';
import { ApiService } from '../api/api.service';



@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  private currentUserProgramPermissions: ProgramUserPermissions;


  constructor(
    private apiService: ApiService
  ) {

  }

  getProgramDetails(): Observable<Program> {
    return this.apiService.getFromProgram('');
  }

  getProgramOverview(setBusyGlobally = true): Observable<ProgramOverview> {
    return this.apiService.getFromProgram('program-overview-web', false, setBusyGlobally)
      .pipe(
        map((item: ProgramOverview) => {
          return new ProgramOverview(item);
        })
      );
  }

  patch(dto: Partial<Program>): Observable<Program> {
    return this.apiService.patchToProgram('', dto);
  }

  put(program: Program): Observable<Program> {
    const dto: any = JSON.parse(JSON.stringify(program));

    return this.apiService.putToProgram('', dto);
  }



  listProgramUsers(userType?: number, setBusyGlobally = true): Observable<User[]> {
    return this.apiService.getFromProgram(`users${userType ? '?userType=' + userType : ''}`, false, setBusyGlobally);
  }

  listProgramUsersPermissions(setBusyGlobally = true): Observable<ProgramUserPermissions[]> {
    return this.apiService.getFromProgram(`users/permissions`, false, setBusyGlobally);
  }

  postProgramUser(userGlobalID: string, roleID?: number): Observable<User> {
    const postObj = { GlobalID: userGlobalID, ProgramRoleID: roleID };
    return this.apiService.postToProgram(`users`, postObj);
  }

  clearProgramUserLocalPermissions(): void {
    this.currentUserProgramPermissions = undefined;
  }

  getProgramUserPermissions(userGlobalID: string, supressErrorMessage = false, setBusyGlobally = true): Observable<ProgramUserPermissions> {
    return this.currentUserProgramPermissions ?
      of(this.currentUserProgramPermissions) :
      this.apiService.getFromProgram(`users/${userGlobalID}/permissions`, supressErrorMessage, setBusyGlobally)
        .pipe(
          map((pup: ProgramUserPermissions) => {
            this.currentUserProgramPermissions = pup;
            return pup;
          })
        );
  }

  getSystemUserPermissions(userGlobalID: string, supressErrorMessage: boolean = false): Observable<SystemUserPermissions> {
    return this.apiService.getFromProgram(`system/users/${userGlobalID}/permissions`, supressErrorMessage)
      .pipe(
        map((sup: SystemUserPermissions) => {
          return sup ? new SystemUserPermissions(sup) : null;
        })
      );
  }

  putProgramUserPermissions(userGlobalID: string, programRoleID: number, isActive: boolean): Observable<ProgramUserPermissions> {
    return this.apiService.putToProgram(`users/${userGlobalID}/permissions`, { ProgramRoleID: programRoleID, IsActive: isActive });
  }

  deleteProgramUser(userGlobalID: string): Observable<ProgramUserPermissions> {
    return this.apiService.deleteToProgram(`users/${userGlobalID}`);
  }

  searchBarcode(barcode: string, supressErrorMessage: boolean = false): Observable<BarcodeRecord[]> {
    return this.apiService.getFromProgram(`searchbarcode/${barcode}`, supressErrorMessage)
      .pipe(
        map((response: any) => {
          const result = [];
          for (const item of response || []) {
            result.push(new BarcodeRecord(item));
          }
          return result;
        })
      );
  }
}
