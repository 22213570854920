import { Injectable } from '@angular/core';
import { ProgramSettingsTypeEnum } from 'geooptix.ng-common/models';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';



@Injectable({
    providedIn: 'root'
})
export class ProgramSettingService {
    constructor(
        private apiService: ApiService,
    ) {

    }

    postProgramSetting(type: ProgramSettingsTypeEnum, setting: any): Observable<any> {
        return this.apiService.postToProgram(`program-settings/program-setting-types/${type}`, setting);
    }

    getAllProgramSettings(): Observable<any> {
        return this.apiService.getFromProgram('program-settings');
    }

    getProgramSettingsForType(type: ProgramSettingsTypeEnum, setBusyGlobally = true): Observable<any[]> {
        return this.apiService.getFromProgram(`program-settings/program-setting-types/${type}`, false, setBusyGlobally);
    }

    getProgramSetting(id: number): Observable<any> {
        return this.apiService.getFromProgram(`program-settings/${id}`);
    }

    putProgramSetting(id: number, setting: any): Observable<any> {
        return this.apiService.putToProgram(`program-settings/${id}`, setting);
    }

    deleteProgramSetting(id: number): Observable<any> {
        return this.apiService.deleteToProgram(`program-settings/${id}`);
    }
}

