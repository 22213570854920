import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieStorageService } from "../../services";


@Injectable({
    providedIn: 'root'
})
export class UnauthorizedAccessGuard implements CanActivate {

    constructor(
        private cookieStorageService: CookieStorageService,
        private router: Router
    ) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const token = this.cookieStorageService.getItem('access_token');
        if (token) {
            return true;
        } else {
            this.router.navigate(['/not-authorized'], { queryParams: { return: state.url } });
            return false;
        }
    }
}
