import { Site } from 'geooptix.ng-common/models';


export class SiteOverview extends Site {

  ProjectCanonicalName: string;
  ProjectName: string;

  ProtocolCount: number;
  SampleCount: number;
  WorkOrderCount: number;

  constructor(item?: any) {
    super(item);
  }

  static clone(item: SiteOverview): SiteOverview {
    return new SiteOverview(
      JSON.parse(JSON.stringify(item))
    );
  }

}
