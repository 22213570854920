export const environment = {
  production: false,
  prefix: 'qa',
  portNumber: '',
  apiPrefix: 'api-qa',
  apiPortNumber: '',
  dataPrefix: 'data-qa',
  dataPortNumber: '',
  searchPrefix: 'search-qa',
  searchPortNumber: '',

  appInsightsKey: '7c7d47bd-de53-4ba4-b0cf-ae174451ce91',

  requestDemoURL: 'http://go.sitkatech.com/l/511561/2018-05-09/2wns4c',

  gTagTrackingId: '',

  chunkSize: 1 * 1024 * 1024,

  geoOptixAuthConfiguration: {
    clientId: 'GeoOptix2Website',
    issuer: 'https://qa.keystone.sitkatech.com/core',
    redirectUri: window.location.origin + '/',

    scope: 'openid all_claims keystone',
    sessionChecksEnabled: true,
    logoutUrl: 'https://qa.keystone.sitkatech.com/core/connect/endsession',
    postLogoutRedirectUri: ''
  }
};
environment.geoOptixAuthConfiguration.postLogoutRedirectUri = 'https://' + environment.prefix + '.geooptix.com' + environment.portNumber + '/';
