import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CookieModule } from 'ngx-cookie';
import { MessageService } from 'primeng/api';
import { BasicTableComponent, FooterComponent, ProgramSelectorComponent, SlidePanelComponent, TopBarComponent, UserAuthenticationComponent, ShowMoreComponent } from './components';
import { ConfirmDialog } from './dialogs';
import { FocusDirective, MaxValueValidatorDirective, MinValueValidatorDirective, UniquePropertyValidatorDirective } from './directives';
import { LoadingModule } from './modules/ngx-loading';
import { PrimeNgModule } from './modules/primeng/primeng.module';
import { LoadingPage, NotAuthorizedPage, NotFoundPage } from './pages';
import { HighlightPipe, TranslatePipe } from './pipes';
import { AceEditorModule } from 'ng2-ace-editor';
import { JSONEditorModule } from 'ngx-jsoneditor';
import { JsonEditorComponent } from '../program/components';



@NgModule({
    imports: [
        CommonModule,
        CookieModule.forRoot(),
        FormsModule,

        AceEditorModule,
        JSONEditorModule,
        LoadingModule,
        PrimeNgModule
    ],
    declarations: [
        BasicTableComponent,
        FooterComponent,
        JsonEditorComponent,
        ProgramSelectorComponent,
        ShowMoreComponent,
        SlidePanelComponent,
        TopBarComponent,
        UserAuthenticationComponent,

        LoadingPage,
        NotFoundPage,
        NotAuthorizedPage,

        ConfirmDialog,

        HighlightPipe,
        TranslatePipe,

        FocusDirective,
        MinValueValidatorDirective,
        MaxValueValidatorDirective,
        UniquePropertyValidatorDirective,
    ],
    exports: [
        CommonModule,
        CookieModule,
        AceEditorModule,
        FormsModule,
        JSONEditorModule,
        LoadingModule,
        PrimeNgModule,

        BasicTableComponent,
        FooterComponent,
        JsonEditorComponent,
        ProgramSelectorComponent,
        ShowMoreComponent,
        SlidePanelComponent,
        TopBarComponent,
        UserAuthenticationComponent,

        LoadingPage,
        NotFoundPage,
        NotAuthorizedPage,

        ConfirmDialog,

        HighlightPipe,
        TranslatePipe,

        FocusDirective,
        MinValueValidatorDirective,
        MaxValueValidatorDirective,
        UniquePropertyValidatorDirective,
    ],
    entryComponents: [
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
    return {
        ngModule: SharedModule,
        providers: [
            MessageService,
        ]
    };
}

    static forChild(): ModuleWithProviders<SharedModule> {
    return {
        ngModule: SharedModule,
        providers: []
    };
}
}
