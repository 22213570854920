import { NgModule, ModuleWithProviders } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { Messages } from 'primeng/messages';
import { FileUpload } from 'primeng/fileupload';


FileUpload.prototype.validate = function validate(file) {
  if (!file?.name) {
    return false;
  } else if (this.accept && !this.isFileTypeValid(file)) {
      this.msgs.push({
          severity: 'error',
          summary: this.invalidFileTypeMessageSummary.replace('{0}', file.name),
          detail: this.invalidFileTypeMessageDetail.replace('{0}', this.accept)
      });
      return false;
  } else if (this.maxFileSize && file.size > this.maxFileSize) {
      this.msgs.push({
          severity: 'error',
          summary: this.invalidFileSizeMessageSummary.replace('{0}', file.name),
          detail: this.invalidFileSizeMessageDetail.replace('{0}', this.formatSize(this.maxFileSize))
      });
      return false;
  }
  return true;
}

// HACK: Fix for issue introduced in v9. disappears in v10
Messages.prototype.hasMessages = function hasMessages() {
  return this.value?.length > 0;
};

@NgModule({
  imports: [
    AccordionModule,
    AutoCompleteModule,
    CardModule,
    CalendarModule,
    CheckboxModule,
    ChipsModule,
    DialogModule,
    DropdownModule,
    FileUploadModule,
    ToastModule,
    InputSwitchModule,
    InputTextModule,
    MenubarModule,
    MenuModule,
    MessageModule,
    MultiSelectModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    RadioButtonModule,
    ScrollPanelModule,
    SelectButtonModule,
    SplitButtonModule,
    TableModule,
    TabViewModule,
    ToggleButtonModule,
    TooltipModule,
    TreeModule,
    TriStateCheckboxModule,
    VirtualScrollerModule,
  ],
  exports: [
    AccordionModule,
    AutoCompleteModule,
    CardModule,
    CalendarModule,
    CheckboxModule,
    ChipsModule,
    DialogModule,
    DropdownModule,
    FileUploadModule,
    ToastModule,
    InputSwitchModule,
    InputTextModule,
    MenubarModule,
    MenuModule,
    MessageModule,
    MultiSelectModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    RadioButtonModule,
    ScrollPanelModule,
    SelectButtonModule,
    SplitButtonModule,
    TableModule,
    TabViewModule,
    ToggleButtonModule,
    TooltipModule,
    TreeModule,
    TriStateCheckboxModule,
    VirtualScrollerModule,
  ],
})
export class PrimeNgModule {
  static forRoot(): ModuleWithProviders<PrimeNgModule> {
    return {
        ngModule: PrimeNgModule,
        providers: []
    };
}
}
