import { IRecordSet, Method, MethodSchemaVersion, Record, RecordSet, RecordSetSchema } from 'geooptix.ng-common/models';

export class BarcodeRecord {

  ObjectType: string;
  ProgramCanonicalName: string;
  ProjectCanonicalName: string;
  SiteCanonicalName: string;
  
  SampleID: number;
  SampleCanonicalName: string;
  SampleName: string;
  ProtocolCanonicalName: string;
  ProtocolVersionNumber: number;
  MethodSchemaCanonicalName: string;
  MethodSchemaVersionNumber: number;
  MethodSchemaVersionID: number;
  RecordSetSchemaPath: string;
  RecordSetSchemaCanonicalName: string;
  RecordSetSchema: RecordSetSchema;
  RecordKey: string;
  Record: Record;
  BarcodeValue: string;

  $Method: Method;
  $MethodSchemaVersion: MethodSchemaVersion;
  $RecordSet: RecordSet;

  constructor(item?: Partial<BarcodeRecord>) {
    Object.assign(this, item);

    this.RecordSetSchema = this.RecordSetSchema ? new RecordSetSchema(this.RecordSetSchema) : null;
    this.Record = this.Record ? new Record(null, this.Record) : null;
  }
}

