

export class Search {
  Count: number;
  Coverage: any;
  Facets: any;
  Results: any[];
}



export class SearchResults {
  Score: number;
  Highlights: any;
  Document: SearchDocument;
}





export class SearchSuggest {
  "@search.coverage": any;
  value: SearchSuggestResults[];
  /**
   *
   */
  constructor(item: SearchSuggest) {
    Object.assign(this, item);    
  }
}

export class SearchSuggestResults {
  "@search.text": string;
  Document: SearchDocument;
}




export class SearchDocument {
  ObjectType: string;
  
  ProgramID: number;
  ProgramCanonicalName: string;
  ProgramName: string;
  
  ProjectID: number;
  ProjectCanonicalName: string;
  ProjectName: string;

  SiteID: number;
  SiteCanonicalName: string;
  SiteName: string;

  WorkOrderID: number;
  WorkOrderCanonicalName: string;
  WorkOrderName: string;

  SampleID: number;

  CanonicalName: string;
  Name: string;
  Tags: string[];
  Description: string;
  StartDate: Date;
  EndDate: Date;

  LastUpdateDate: Date;
  SearchKey: string;
}
