import { Site } from 'geooptix.ng-common/models';
import { Station } from './station.model';


export class StationOverview extends Station {

  ProjectName: string;

  Site: Site;
  HasFilesOrFolders: boolean;

  constructor(item?: any) {
    super(item);

    this.Site = new Site(item.Site);
  }

  static clone(item: StationOverview): StationOverview {
    return new StationOverview(
      JSON.parse(JSON.stringify(item))
    );
  }

}
