import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class CookieStorageService extends OAuthStorage {

  constructor(
    private cookieService: CookieService,
  ) {
    super();
  }

  getItem(key: string): string | null {
      return this.cookieService.get(key);
  }

  removeItem(key: string): void {
    return this.cookieService.remove(key);
  }

  removeAll(): void {
    return this.cookieService.removeAll({
      domain: this.getCookieDomainForEnvironment(),
    });
  }

  removeAllProd(): void {
    return this.cookieService.removeAll({
      domain: 'geooptix.com',
    });
  }

  setItem(key: string, data: string): void {
    return this.cookieService.put(key, data, {
      domain: this.getCookieDomainForEnvironment(),
    })
  }

  private getCookieDomainForEnvironment(): string {
    if (environment.prefix === 'www') {
      return 'geooptix.com';
    }
    return `${environment.prefix}.geooptix.com`;
  }
}