import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProgramSettingsTypeEnum } from 'geooptix.ng-common/models';
import { Observable, of, zip } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { catchError, map } from 'rxjs/operators';
import { ProgramSettingService } from '../program-setting/program-setting.service';
const { version } = require('../../../../../package.json');

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  aliasesSetting: any;
  texts: any;

  constructor(
    private http: HttpClient,
    private programSettingService: ProgramSettingService,
  ) {

  }

  init(): Observable<void> {
    return this.http.get('/assets/i18n/en.json?r=' + version)
    .pipe(
      mergeMap((response: any) => {
        this.texts = response || {};

        return this.programSettingService.getProgramSettingsForType(ProgramSettingsTypeEnum.Aliases);
      }),
      map((response: any) => {
        if (response.length && response[0].Setting) {
          this.aliasesSetting = response[0].Setting;
        } else {
          this.aliasesSetting = {};
        }
      }),
      catchError((error: any) => {
        console.error(error);
        return of(null);
      })
    );
  }

  instant(originalString: string): string {
    if (this.texts) {
      let result = this.deepFind(this.texts, originalString) || originalString || '';

      // we want to replace the plural forms first so sort the keys desc
      const sortedAliasesKeys = Object.keys(this.aliasesSetting || {})
      .sort((a, b) => {
        return b.localeCompare(a);
      });
      for (const key of sortedAliasesKeys) {
        result = result.replace(new RegExp(key, 'g'), this.aliasesSetting[key]);
      }

      return result;
    } else if (originalString === originalString.toUpperCase()) { // Don't return the JSON key strings
      return '';
    } else {
      return originalString;
    }
  }

  deepFind(obj: any, path: string) {
    const paths = path.split('.');
    let current = obj;

    for (let i = 0; i < paths.length; ++i) {
      if (!current || current[paths[i]] == undefined) {
        return undefined;
      } else {
        current = current[paths[i]];
      }
    }
    return current;
  }
}